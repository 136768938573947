import * as actionTypes from "../actions/actionTypes";
import useLocalStorage from "../../utils/localStorage";
import CommonEndpoints from "../../services/commonApi.service";
import useApiService from "../../services/api.service";

const localStorage = useLocalStorage;
const ApiService = useApiService();

const onLoaded = (state: any, action: any) => {
  return {
    ...state,
    onLoaded: { ...action.data },
  };
};

const app = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_VIA_ACCESS_TOKEN:
    case actionTypes.LOGIN_RESPONSE:
    case actionTypes.LOGOUT:
    case actionTypes.LOADER:
      return onLoaded(state, action);
    // const actionTypes.ON_DROPDOWN_CHANGE_HEADER:
    //     return
    default:
      return state;
  }
};

export default app;
