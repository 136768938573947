import React, { useEffect, useState } from "react";
// import i18n from './i18n';
import AppRoutes from "./App.routing";
import "./App.scss";
import { withRouter } from "react-router";
//import HeaderSidebarWrapper from "./components/HeaderSidebarWrapper/HeaderSidebarWrapper";
import Toast from "./components/Toast/Toast";
// import GoogleAutocompleteService from './utils/googleAutocompleteService';
// import { loadThirdPartyScript } from './utils/loadThirdPartyScript';
import appEndpoints from "./App.endpoints";
import useApiService from "./services/api.service";
import * as actions from "./AppContext/actions/actions";
import PageLoader from "./components/pageLoader/pageLoader";
import useLocalStorage from "./utils/localStorage";
import setVariables from "./utils/setVariables";
import { UserType } from "./utils/userType.enum";
import Header from "../src/containers/Header/Header";
import { useTranslation } from "react-i18next";
// import Resource  Details from "./containers/ResourceDetails/ResourceMobileDetails";
import "../src/utils/leaflet/css/leaflet.scss";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
const exclusionArray = [
  "/login",
  "/register",
  "/step1",
  "/step2",
  "/resetpassword",
];

function App({ location }: any) {
  const { i18n } = useTranslation();
  const [img] = useState() as any;
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState() as any;
  // let [, setShowChatDropdown] = useState(false);
  const [, setCheckData]: any = useState();

  const ApiService: any = useApiService();
  const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;

  const [domainData, setDomainData]: any = useState({});

  const toggleCropModal = () => {
    setCropModalVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (img) {
      console.log(img);
      setCropModalVisible(true);
    }
  }, [img]);

  const getCroppedImage = (arg: any) => {
    setCroppedImageUrl(arg);
  };

  useEffect(() => {
    console.log(croppedImageUrl);
  }, [croppedImageUrl]);

  useEffect(() => {
    if (Object.keys(domainData).length) {
      setVariables(domainData);
      if (domainData.ds_favicon) {
        const icon = document.getElementById("favicon") as any;
        icon.href = domainData.ds_favicon;
        const appleIcon = document.getElementById("apple-icon") as any;
        appleIcon.href = domainData.ds_favicon;
      }

      document.title = domainData.ds_favicon_title || document.title;
    }
  }, [domainData]);

  const changeHandler = (ev: any) => {
    i18n.changeLanguage(ev);
  };

  return (
    <div className={isHeaderSidemenuVisible ? "app" : ""}>
      <div className={isHeaderSidemenuVisible ? "app__outer" : ""}>
        {/* <Header /> */}
        <AppRoutes></AppRoutes>
      </div>
      <Toast></Toast>
    </div>
  );
}

App.whyDidYouRender = true;

export default withRouter(App);
