import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router";
// import { useAppContext } from "./AppContext/App.context";
import useLocalStorage from "./hooks/localStorage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PageLoader from "./components/pageLoader/pageLoader";

// import GoDaddy from "./containers/Domains/GoDaddy/GoDaddy";

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
// eslint-disable-next-line
const whyDidYouRender = require("@welldone-software/why-did-you-render");

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/:token"></Redirect>
      </Route>
      <PrivateRoute exact path="/:token" component={Dashboard} />
    </Switch>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
