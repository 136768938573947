// import firebase from "firebase/app";
// import "@firebase/messaging";
import * as firebase from "firebase";
// import { async } from "rxjs";
export const initializeFirebase = () => {
  const config = {
    apiKey: "apikey",
    authDomain: "xyz-pwa-d.firebaseapp.com",
    databaseURL: "https://xyz-pwa-d.firebaseio.com",
    projectId: "zyz-pwa-f5e5b",
    storageBucket: "xyz-pwa-d.appspot.com",
    messagingSenderId: "dd",
    appId: "1:dd:web:dd",
    measurementId: "G-dd",
  };
  firebase.initializeApp(config);
};
export const askForPermissionToReceiveNotificaitons = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log("token==", token);
    localStorage.setItem("device_token", token);
    return token;
  } catch (err) {
    console.log(err);
  }
};

// const config = {
//   messagingSenderId: "<your-app-messaging-sender-id>",
// };

// firebase.initializeApp(config);

// let messaging;

// // we need to check if messaging is supported by the browser
// if (firebase.messaging.isSupported()) {
//   messaging = firebase.messaging();
// }
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", async () => {
//     const registration = await navigator.serviceWorker.register(
//       "/firebase-messaging-sw.js",
//       {
//         updateViaCache: "none",
//       }
//     );
//     messaging.useServiceWorker(registration);

//     messaging.onMessage((payload) => {
//       const title = payload.notification.title;
//       const options = {
//         body: payload.notification.body,
//         icon: payload.notification.icon,
//         actions: [
//           {
//             action: payload.fcmOptions.link,
//             title: "Book Appointment",
//           },
//         ],
//       };
//       registration.showNotification(title, options);
//     });
//   });
// }

// export { messaging };
