import React, { Fragment } from "react";
import "./pageLoader.scss";
import "../modal/modal.scss";
import loadingGif from "../../assets/images/loading.gif";
import CircularProgress from "@material-ui/core/CircularProgress";

const PageLoader = () => {
  return (
    <Fragment>
      <div className="pageLoader">
        <div>
          {/* <img src={loadingGif}></img> */}
          <CircularProgress size={50} />
        </div>
      </div>
    </Fragment>
  );
};
export default PageLoader;
